import "../styles/Product.scss";

import React, { useState } from "react";

import { Link } from "gatsby";
import { motion } from "framer-motion";
import slugify from "slugify";

function Product({ titre, bigwaxId, prix, images, index }) {
  const [selectedImage, setSelectedImage] = useState(0);

  return (
    <div className="product-inner">
      <div className="image-container">
        {images.map((image, i) => {
          return (
            <Link
              to={"/produit/" + slugify(titre, { lower: true })}
              key={titre + " image " + i}
            >
              <motion.img
                animate={{
                  opacity: selectedImage === i ? 1 : 0,
                  pointerEvents: selectedImage === i ? "auto" : "none",
                }}
                src={image}
                alt={titre + " image " + i}
                width="250px"
                height="250px"
              />
            </Link>
          );
        })}
      </div>
      <div className="title-container">{titre}</div>
      <div className="info-container">
        <div className="info-options">
          {images.map((image, i) => {
            if (i < 4) {
              return (
                <div
                  className={selectedImage === i ? "circle selected" : "circle"}
                  onClick={() => setSelectedImage(i)}
                  onKeyPress={() => setSelectedImage(i)}
                  role="button"
                  tabIndex={0}
                  aria-label="Autres images"
                  key={i}
                ></div>
              );
            }
            return null;
          })}
        </div>
        <div className="info-prix">{prix}€</div>
        <div className="info-buy">
          <Link
            to={"produit/" + slugify(titre, { lower: true })}
            className="gg-shopping-cart"
          />
        </div>
      </div>
    </div>
  );
}

export default Product;
