import "../styles/index.scss";

import Div100vh from "react-div-100vh";
import Footer from "../components/Footer.jsx";
import Logo from "../components/Logo.jsx";
import Navbar from "../components/Navbar.jsx";
import React from "react";
import Shop from "../components/Shop.jsx";

// markup
const IndexPage = () => {
  return (
    <div className="main">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
      />
      <Div100vh style={{ width: "100%" }}>
        <Navbar currentPage="Accueil" />
        <Logo />
      </Div100vh>
      <Shop />
      <Footer />
    </div>
  );
};

export default IndexPage;
