import "../styles/ProductList.scss";

import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";

import Product from "./Product.jsx";
import ProductsContext from "../context/ProductsContext.jsx";
import { window } from "browser-monads";

function ProductsList({ filterCat, filterSearch }) {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [listOfFilteredProducts, setListOfFilteredProducts] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //RÉCUPÈRE LA LISTE DES PRODUITS SUR CONTENTFUL
  const allProducts = useContext(ProductsContext);

  //DÉTECTE ET ENREGISTRE LA LARGEUR DE LA FENÊTRE
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  //GARDE UNIQUEMENT LES PRODUITS CONTENTFUL DONT LA CATÉGORIE EST DANS LE FILTRE
  useEffect(() => {
    let tempFilteredProducts = [];

    if (allProducts.length > 0) {
      allProducts.forEach((back_product) => {
        let isProductCatInFilterCat = back_product.categories.some((r) => {
          if (filterCat.includes(r.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
        let isProductSearched = back_product.titre
          .toLowerCase()
          .includes(filterSearch.toLowerCase());
        if (isProductCatInFilterCat && isProductSearched) {
          tempFilteredProducts.push(back_product);
        }
      });
      setFilteredProducts(tempFilteredProducts);
    }
  }, [allProducts, filterCat, filterSearch]);

  //MAP LES PRODUITS FILTRÉS ET RENDER LA LISTE DES PRODUITS
  useEffect(() => {
    let tempListofProducts = filteredProducts.map((product, i) => {
      return (
        <motion.div
          key={product.titre}
          initial={{
            opacity: 0,
            width: windowWidth < 605 ? 250 : 0,
            height: windowWidth < 605 ? 0 : 305,
            margin: windowWidth < 605 ? "0px 0px 0px 0px" : "20px 0px 20px 0px",
          }}
          animate={{
            opacity: 1,
            width: 250,
            height: 305,
            margin: "20px 20px 20px 20px",
          }}
          exit={{
            opacity: 0,
            width: windowWidth < 605 ? 250 : 0,
            height: windowWidth < 605 ? 0 : 305,
            margin: windowWidth < 605 ? "0px 0px 0px 0px" : "20px 0px 20px 0px",
          }}
          transition={{
            type: "ease-in-out",
            duration: 0.4,
          }}
          // transition={{ type: "tween", duration: 0.3 }}
          className="product-container"
        >
          <Product
            titre={product.titre}
            bigwaxId={product.bigwaxId}
            prix={product.prix}
            images={product.images}
            index={i}
          />
        </motion.div>
      );
    });
    setListOfFilteredProducts(tempListofProducts);
  }, [filteredProducts, windowWidth]);

  return (
    <div className="productList-container">
      <AnimatePresence>{listOfFilteredProducts}</AnimatePresence>
    </div>
  );
}

export default ProductsList;
