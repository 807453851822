import "../styles/Shop.scss";

import React, { useContext, useState } from "react";

import LocaleContext from "../context/LocaleContext.jsx";
import ProductsList from "./ProductsList.jsx";
import ShopFilters from "./ShopFilters.jsx";

function Shop(props) {
  const [locale] = useContext(LocaleContext);
  const categories = [
    "albums",
    "mixtapes",
    "vêtements",
    "accessoires",
    "autres",
  ];
  const categoriesDisplay =
    locale === "fr"
      ? categories
      : ["albums", "mixtapes", "clothings", "accessories", "others"];

  const [filterCat, setFilterCat] = useState(categories);
  const [filterSearch, setFilterSearch] = useState("");

  return (
    <div className="shop-container">
      <ShopFilters
        categories={categories}
        categoriesDisplay={categoriesDisplay}
        filterCat={filterCat}
        setFilterCat={(e) => {
          setFilterCat(e);
        }}
        filterSearch={filterSearch}
        setFilterSearch={(e) => setFilterSearch(e)}
      />
      <ProductsList filterCat={filterCat} filterSearch={filterSearch} />
    </div>
  );
}

export default Shop;
