import { AnimatePresence, motion } from "framer-motion";

import React from "react";

function FilterSubitem({
  categorie,
  categoriesDisplay,
  filterCat,
  setFilterCat,
  variants,
}) {
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <motion.div
      variants={variants}
      className={
        filterCat.includes(categorie)
          ? "filter-subitem active"
          : "filter-subitem"
      }
      onClick={() => {
        let tempsFiltersAr = [];
        for (let i = 0; i < filterCat.length; i++) {
          tempsFiltersAr.push(filterCat[i]);
        }
        if (tempsFiltersAr.includes(categorie)) {
          tempsFiltersAr.splice(filterCat.indexOf(categorie), 1);
        } else {
          tempsFiltersAr.push(categorie);
        }
        setFilterCat(tempsFiltersAr);
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {filterCat.includes(categorie) ? (
          <motion.i
            key="checked"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="gg-check-o"
          ></motion.i>
        ) : (
          <motion.i
            key="uncheck"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="gg-radio-check"
          ></motion.i>
        )}
      </AnimatePresence>
      {capitalize(categoriesDisplay)}
    </motion.div>
  );
}

export default FilterSubitem;
