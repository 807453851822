import "../styles/ShopFilters.scss";

import React, { useEffect, useState } from "react";

import FilterSubitem from "./FilterSubitem.jsx";
import { motion } from "framer-motion";

function ShopFilters({
  categories,
  categoriesDisplay,
  filterCat,
  setFilterCat,
  filterSearch,
  setFilterSearch,
}) {
  const [showFilters, setShowFilters] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const filterContainer = {
    hidden: {
      transition: {
        staggerChildren: 0.08,
        staggerDirection: -1,
      },
    },
    show: {
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  useEffect(() => {
    if (!showSearch) {
      setFilterSearch("");
    }
  }, [showSearch, setFilterSearch]);

  const filterItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <div className="shopfilter-container">
      <motion.div
        animate={{
          backgroundColor: showFilters ? "#ffffff" : "#1b1b1b",
        }}
        className="sf-item toggle filters"
        onClick={() => {
          setShowFilters(!showFilters);
          setShowSearch(false);
        }}
      >
        <div className={showFilters ? "sf-content active" : "sf-content"}>
          <i className="gg-filters"></i>
        </div>
      </motion.div>
      <div className="sf-item action filters">
        <motion.div
          variants={filterContainer}
          initial="hidden"
          animate={showFilters ? "show" : "hidden"}
          className={showFilters ? "filters-content show" : "filters-content"}
        >
          {/* <motion.div variants={filterItem} className="filter-item">
            Musique ❯
          </motion.div> */}
          <motion.div
            variants={filterItem}
            className="filter-item"
            onClick={() => {
              if (
                filterCat.length === 0 ||
                filterCat.length !== categories.length
              ) {
                setFilterCat(categories);
              } else {
                setFilterCat([]);
              }
            }}
          >
            {filterCat.length === 0 ? (
              <motion.i
                key="checked"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="gg-radio-check"
              ></motion.i>
            ) : filterCat.length !== categories.length ? (
              <motion.i
                key="uncheck"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="gg-radio-half"
              ></motion.i>
            ) : (
              <motion.i
                key="half"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="gg-radio-checked"
              ></motion.i>
            )}
          </motion.div>
          {categories.map((categorie, i) => {
            return (
              <FilterSubitem
                categorie={categorie}
                categoriesDisplay={categoriesDisplay[i]}
                filterCat={filterCat}
                setFilterCat={(e) => setFilterCat(e)}
                variants={filterItem}
                key={i}
              />
            );
          })}
          {/* <motion.div variants={filterItem} className="filter-item">
            Merch ❯
          </motion.div> */}
        </motion.div>
      </div>
      <motion.div
        initial={{ transform: "translateX(230px)" }}
        animate={{
          transform: showSearch ? "translateX(65px)" : "translateX(230px)",
        }}
        onAnimationComplete={() => {
          if (showSearch) {
            document.getElementById("search-input").focus();
          }
        }}
        className="sf-item action search"
      >
        <label htmlFor="search-input">Rechercher un produits</label>
        <input
          id="search-input"
          className="sf-content"
          onInput={(e) => setFilterSearch(e.target.value)}
          value={filterSearch}
          placeholder="RECHERCHER..."
        />
      </motion.div>
      <motion.div
        animate={{
          backgroundColor: showSearch ? "#ffffff" : "#1b1b1b",
        }}
        className="sf-item toggle search"
        onClick={() => {
          document.getElementById("facebook-logo").focus();
          setShowFilters(false);
          setShowSearch(!showSearch);
        }}
      >
        <div className={showSearch ? "sf-content active" : "sf-content"}>
          <i className="gg-search"></i>{" "}
        </div>
      </motion.div>
    </div>
  );
}

export default ShopFilters;
