import "../styles/Logo.scss";

import React, { useState, useEffect } from "react";

import LOGO_SNIPER_NOW from "../images/LOGO-SNIPER-NOW.png";
import LOGO_SNIPER_OLD from "../images/LOGO-SNIPER-OLD.png";
import ReactCompareImage from "react-compare-image";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
// import silhouettes from "../images/SNIPER_SILHOUETTES.jpg";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

function Logo(props) {
  const [scrollY, setScrollY] = useState(0);

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollY(currPos.y);
  });

  const scrollToShop = () => {
    window.scrollTo(0, (90 * window.innerHeight) / 100);
  };

  useEffect(() => {
    let timer1 = setTimeout(scrollToShop, 6000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className="logo-container" onClick={scrollToShop} role="presentation">
      <motion.div
        style={{ translateY: -0.65 * scrollY }}
        // transition={{ type: "spring", bounce: 0.1, mass: 0.1 }}
        className="logo-bg-container"
      >
        <StaticImage
          src="../images/SNIPER_SILHOUETTES.jpg"
          alt="Silhouettes Sniper"
        />
      </motion.div>
      <div className="logo-compare-container">
        <div className="logo-compare">
          <ReactCompareImage
            leftImage={LOGO_SNIPER_OLD}
            rightImage={LOGO_SNIPER_NOW}
            sliderPositionPercentage={Math.round(Math.random())}
            hover
          />
        </div>
      </div>
    </div>
  );
}

export default Logo;
